import React, {useEffect, useReducer, useState} from "react";
import gql from "graphql-tag";
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {client} from '../apollo/client';

const DATO_UPLOAD_REQUEST = gql`
    query DatoUploadRequest($input: DatoUploadRequestInput!) {
        datoUploadRequest(input: $input) {
            id
            url
        }
    }
`


const ImageUpload = ({image: imageInit, setImage: setImageParent, className}) => {
  const imageReducer = (image, v) => {
    switch (v.type) {
      case 'replace':
        return {url: v.url};
      case 'update':
        return {...image, path: v.path};
      case 'delete':
        return null
      default:
        throw new Error()
    }
  }
  const [image, setImage] = useReducer(imageReducer, imageInit)
  const [percentage, setPercentage] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    setImageParent(image && {id: image.id, path: image.path})
  }, [image, setImageParent])

  return <div className={className}>

    {image && <div className="relative">
      <img src={image.url} alt="" className=''/>
      {percentage === null ?
        null :
        <CircularProgressbar value={percentage} text={`${percentage}%`} className='absolute inset-0 w-20 h-20 my-8'/>}
      <button
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setImage({type: 'delete'})
        }}
        className='uppercase absolute right-0 top-0 z-50 py-1 px-2 focus:outline-none bg-red-600 font-title font-light text-white text-center tracking-wide text-lg'>
        Delete
      </button>
    </div>}
    {error ?
      <div className="font-sans font-simple bg-red-300 p-1 my-1">Something went wrong, please try again later.</div> :
      null
    }
    <label className='flex justify-center mt-1 cursor-pointer' htmlFor="fileInput">
      <span className="bg-yellow-700 font-title tracking-wide text-white px-4 py-2">Select a file</span>
      <input type="file" id="fileInput" className='hidden'
             accept="image/*"
             onChange={async ({target: {files: [file]}}) => {
               setError(false)
               setPercentage(0)

               const reader = new FileReader()
               reader.readAsDataURL(file)
               reader.onload = e => setImage({type: 'replace', url: e.target.result})

               const {data: {datoUploadRequest: {id, url}}} = await client.query({
                 query: DATO_UPLOAD_REQUEST,
                 variables: {input: {filename: file.name}}
               });
               const xhr = new XMLHttpRequest()
               xhr.open('PUT', url)
               xhr.onload = () => {
                 setImage({type: 'update', path: id})
                 setPercentage(null)
               }
               xhr.onerror = e => {
                 console.error(e)
                 setError(true)
                 setPercentage(null)
               }
               xhr.upload.onprogress = e => setPercentage(Math.round(e.loaded / e.total * 100))
               xhr.send(file)
             }}
      />
    </label>
  </div>
}

export default ImageUpload