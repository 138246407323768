import React, {useEffect, useReducer, useState} from "react";
import gql from "graphql-tag";
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {client} from '../apollo/client';

const DATO_UPLOAD_REQUEST = gql`
    query DatoUploadRequest($input: DatoUploadRequestInput!) {
        datoUploadRequest(input: $input) {
            id
            url
        }
    }
`


const ImagesUpload = ({images: imagesInit, setImages: setImagesParent, className}) => {
  const imagesReducer = (images, v) => {
    switch (v.type) {
      case 'add':
        return [...images, v];
      case 'update':
        return images.map(image => {
          if (image.name === v.name) {
            return {...image, ...v}
          }
          return image;
        })
      case 'delete':
        if (v.name) {
          return images.filter(i => !i.name || i.name !== v.name)
        } else {
          return images.filter(i => !i.id || i.id !== v.id)
        }
      default:
        throw new Error()
    }
  }
  const [images, setImages] = useReducer(imagesReducer, imagesInit)
  const [error, setError] = useState(false)

  useEffect(() => {
    setImagesParent(images.map(i => ({id: i.id, path: i.path})))
  }, [images, setImagesParent])

  return <div className={className}>
    <div className="flex flex-wrap">
      {images.map(image => {
          const percentage = image.percentage
          return <div className="relative w-1/2 pr-1 pb-1" key={image.url}>
            <img src={image.url} alt="" className=''/>
            {percentage === null || percentage === undefined ?
              null :
              <CircularProgressbar value={percentage} text={`${percentage}%`}
                                   className='absolute inset-0 w-20 h-20 my-8'/>}
            <button
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setImages({type: 'delete', name: image.name, id: image.id})
              }}
              className='uppercase absolute right-0 top-0 z-50 py-1 mr-1 px-2 focus:outline-none bg-red-600 font-title font-light text-white text-center tracking-wide text-lg'>
              Delete
            </button>
          </div>;
        }
      )}
    </div>
    {error ?
      <div className="font-sans font-simple bg-red-300 p-1 my-1">Something went wrong, please try again later.</div> :
      null
    }
    <label className='flex justify-center mt-1 cursor-pointer' htmlFor="filesInput">
      <span className="bg-yellow-700 font-title tracking-wide text-white px-4 py-2">Select a file</span>
      <input type="file" id="filesInput" className='hidden'
             accept="image/*"
             multiple={true}
             onChange={async ({target: {files}}) => {
               setError(false)

               Array.from(files).forEach(async file => {
                 const {data: {datoUploadRequest: {id, url}}} = await client.query({
                   query: DATO_UPLOAD_REQUEST,
                   variables: {input: {filename: file.name}}
                 });

                 const reader = new FileReader()
                 reader.readAsDataURL(file)
                 reader.onload = e => {
                   return setImages({type: 'add', url: e.target.result, percentage: null, name: file.name});
                 }

                 const xhr = new XMLHttpRequest()
                 xhr.open('PUT', url)
                 xhr.onload = (e) => {
                   setImages({
                     type: 'update',
                     name: file.name,
                     percentage: null,
                     path: id
                   })
                 }
                 xhr.onerror = (e) => {
                   console.error(e)
                   setError(true)
                   setImages({
                     type: 'update',
                     name: file.name,
                     percentage: null
                   })
                 }
                 xhr.upload.onprogress = (e) => setImages({
                   type: 'update',
                   name: file.name,
                   percentage: Math.round(e.loaded / e.total * 100)
                 })
                 xhr.send(file)
               })
             }}
      />

    </label>
  </div>
}

export default ImagesUpload