import React, {useContext, useState} from "react";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image"
import '../aboutus.css'
import gql from "graphql-tag";
import {Mutation, Query} from "react-apollo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {ErrorMessage, Field, Form, Formik} from "formik";
import moment from "moment";
import * as Yup from 'yup';
import ImageUpload from "../../components/imageUpload";
import ImagesUpload from "../../components/imagesUpload";
import {client} from '../../apollo/client';
import MyAccountLayout from "../../components/myAccountLayout";
import {UserContext} from "../../context/UserContext";

const STYLIST_PROFILE = gql`
    query StylistProfile {
        stylistProfile {
            address
            address2
            city
            state
            zip
            country
            phone
            birthdate
            portfolio
            languages
            education
            school
            experience
            menWomen
            favoriteStyle
            citiesOfWork
            countriesOfWork
            commitment
            featured
            ambassador
            availabilities
            contributions
            avatar {
                id
                url
            }
            images {
                id
                url
            }
            shoppingCity
            category
            hourlyRateDollar
            dailyRateDollar
            contributions
            yearsOfFashionStylingExperience
            yearsOfMakeupStylingExperience
            yearsOfHairStylingExperience
        }
    }
`

const UPSERT_STYLIST_PROFILE = gql`
    mutation UpsertStylistProfile($input:StylistProfileInput!) {
        upsertStylistProfile(input:$input){
            zip
        }
    }`

const initStylistProfile = {
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  phone: "",
  birthdate: "",
  portfolio: "",
  languages: "",
  education: undefined,
  school: "",
  experience: "",
  menWomen: undefined,
  favoriteStyle: "",
  citiesOfWork: "",
  countriesOfWork: "",
  commitment: undefined,
  featured: undefined,
  ambassador: undefined,
  availabilities: [],
  contributions: [],
  images: "",
  shoppingCity: "",
  category: [],
  hourlyRateDollar: '',
  dailyRateDollar: '',
  yearsOfFashionStylingExperience: '',
  yearsOfMakeupStylingExperience: '',
  yearsOfHairStylingExperience: '',
}


const F = ({label, children}) => <div className='pt-4 pb-2 w-full flex flex-col'>
  <h4 className='font-sans font-light text-xl'>{label}</h4>
  {children}
</div>

const RadioButton = ({
                       name,
                       value,
                       label,
                       onChange = () => {
                       }
                     }) => <label htmlFor={value}
                                  className={'font-sans font-light text-gray-700 text-base mr-8'}>
  <Field name={name}
         render={({field, form}) => <input onClick={() => {
           onChange(field, form);
         }
         } id={value} className={'mr-2'} type="radio" {...field}
                                           checked={field.value === value} value={value}/>}/>{label}
</label>

const TField = ({name, placeholder, ...rest}) => {
  return <Field name={name}
                className='font-sans font-light border flex-1 m-1 p-1'
                placeholder={placeholder} {...rest}/>;
}


const Checkbox = ({name, value}) => <Field name={name}>
  {({field, form}) => {
    return (
      <label className='w-full py-2'>
        <div
          className={"inline-block mr-2 border border-black" + (field.value && field.value.includes(value) ? ' bg-yellow-700' : '')}>
          <input
            className='invisible'
            type="checkbox"
            name={name}
            value={value}
            checked={field.value && field.value.includes(value)}
            onChange={() => {
              if (field.value && field.value.includes(value)) {
                const nextValue = field.value.filter(
                  v => v !== value
                );
                form.setFieldValue(name, nextValue);
              } else {
                const nextValue = (field.value || []).concat(value);
                form.setFieldValue(name, nextValue);
              }
            }}
          />
        </div>
        <span className="font-sans font-light text-gray-800 text-base">
        {value.replace(/_/g, ' ')}
        </span>
      </label>
    );
  }}
</Field>;

const CheckboxGroup = ({name, values}) => <div className="flex flex-col w-full">
  <div className="flex flex-wrap">
    {values.map(v => <Checkbox key={v} className='w-full bg-blue' name={name} value={v}/>)}
  </div>
</div>

const numberDefinition = Yup.number().typeError('must be a number').positive('must be positive').integer('must not contain comma')

const SignupSchema = Yup.object().shape({
  yearsOfHairStylingExperience: numberDefinition,
  yearsOfMakeupStylingExperience: numberDefinition,
  yearsOfFashionStylingExperience: numberDefinition,
  hourlyRateDollar: numberDefinition,
  dailyRateDollar: numberDefinition,
  birthdateDay: numberDefinition,
  birthdateMonth: numberDefinition,
  birthdateYear: numberDefinition,
});

const StylistProfile = () => {
  const [avatar, setAvatar] = useState(null)
  const [images, setImages] = useState([])
  const data = useStaticQuery(query)
  const {datoCmsStylistformpage: p} = data
  const userContext = useContext(UserContext);
  return <MyAccountLayout>
    <div className="flex items-start flex-wrap">
      <div className="w-full lg:w-1/2  p-2 lg:p-8">
        <h1
          className='font-title text-4xl uppercase lg:mt-8 lg:mb-2 text-yellow-700 text-center tracking-wide'>Become a
          LookStyler</h1>
        <Query
          client={client}
          query={STYLIST_PROFILE}
          onCompleted={data => {
            if (data.stylistProfile) {
              setAvatar(data.stylistProfile.avatar)
            }
          }}
        >
          {({loading, error, data}) => {
            if (loading) {
              return <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/>
            }
            if (error) {
              return <div className="font-sans text-red-700">Something went wrong, please try again later</div>
            }
            return <Mutation
              client={client}
              mutation={UPSERT_STYLIST_PROFILE}
            >
              {(upsertStylistProfile) =>
                <Formik
                  initialValues={(() => {
                    const {stylistProfile} = data;
                    if (stylistProfile) {
                      stylistProfile.education = stylistProfile.education ? 'true' : 'false'
                      stylistProfile.category = stylistProfile.category || []
                      if (stylistProfile.birthdate) {
                        const d = moment(stylistProfile.birthdate)
                        return {
                          ...stylistProfile,
                          birthdateDay: d.date(),
                          birthdateMonth: d.month() + 1,
                          birthdateYear: d.year()
                        }
                      } else {
                        return {
                          ...stylistProfile,
                          birthdateDay: '',
                          birthdateMonth: '',
                          birthdateYear: ''
                        }

                      }
                    } else {
                      return initStylistProfile
                    }
                  })()}
                  validationSchema={SignupSchema}
                  onSubmit={async ({recaptcha, ...input}, {setSubmitting, setStatus}) => {
                    setStatus(null)
                    delete input['__typename']
                    input.birthdate = moment({
                      year: input.birthdateYear,
                      month: input.birthdateMonth - 1,
                      day: input.birthdateDay
                    }).toDate()
                    input.education = (input.education === 'true')
                    delete input.birthdateDay
                    delete input.birthdateMonth
                    delete input.birthdateYear

                    input.avatar = avatar
                    input.images = images
                    setSubmitting(false)
                    upsertStylistProfile({variables: {input}})
                      .then(r => {
                        setStatus({error: false});
                        setSubmitting(false);
                      })
                      .catch(e => {
                        console.error(e)
                        setStatus({error: true});
                        setSubmitting(false);
                      })
                  }}
                >
                  {({values, status, isSubmitting}) => {
                    return <Form className={'flex-1'}>
                      <F label={'Personal Information'}>
                        <div className="font-sans font-light">{userContext.getFullName()}</div>
                        <div
                          className="font-sans font-light">{userContext.user.idToken.payload.email}</div>
                      </F>
                      <F label='Address'>
                        <TField name='address' placeholder='Address line 1'/>
                        <TField name='address2' placeholder='Address line 2'/>
                        <div className='w-full flex flex-wrap'>
                          <TField name='country' placeholder='Country'/>
                          <TField name='city' placeholder='City'/>
                          <TField name='state' placeholder='State'/>
                        </div>
                        <div className='w-full flex flex-wrap'>
                          <TField name='zip' placeholder='Zip code'/>
                          <TField name='phone' placeholder='Contact number'/>
                        </div>
                      </F>
                      <F label='Date of birth'>
                        <div className="flex flex-wrap">
                          <TField name='birthdateDay' placeholder='DD' maxLength='2'/>
                          <TField name='birthdateMonth' placeholder='MM' maxLength='2'/>
                          <TField name='birthdateYear' placeholder='YYYY' maxLength='4'/>
                          <ErrorMessage name="birthdateDay" component='div' className='font-sans bg-red-300 p-1 m-1'/>
                          <ErrorMessage name="birthdateMonth" component='div' className='font-sans bg-red-300 p-1 m-1'/>
                          <ErrorMessage name="birthdateYear" component='div' className='font-sans bg-red-300 p-1 m-1'/>
                        </div>
                      </F>
                      <F label='Category'>
                        <CheckboxGroup name='category' values={[
                          'fashion',
                          'hair',
                          'makeup',
                          'photography'
                        ]}/>
                      </F>
                      <F label='Portfolio Link'>
                        <TField name='portfolio' placeholder='www.example.com'/>
                      </F>
                      <div className='pt-4 pb-2 flex flex-col justify-center items-start'>
                        <h4 className='font-sans font-light text-xl'>Avatar</h4>
                        <ImageUpload
                          image={data.stylistProfile && data.stylistProfile.avatar}
                          setImage={setAvatar}
                          className='w-1/2'
                        />
                      </div>
                      <div className='pt-4 pb-2 flex flex-col justify-center items-start'>
                        <h4 className='font-sans font-light text-xl'>Please Upload Your Best Portfolio Pictures</h4>
                        <ImagesUpload images={(data.stylistProfile && data.stylistProfile.images) || []}
                                      setImages={setImages}/>
                      </div>
                      <F label='Working Languages'>
                        <TField name='languages' rows={3} component='textarea'/>
                      </F>
                      <F label='Years of Hair Styling Experience'>
                        <TField name='yearsOfHairStylingExperience'/>
                        <ErrorMessage name="yearsOfHairStylingExperience" component='div'
                                      className='font-sans bg-red-300 p-1'/>
                      </F>
                      <F label='Years of Makeup Styling Experience'>
                        <TField name='yearsOfMakeupStylingExperience'/>
                        <ErrorMessage name="yearsOfMakeupStylingExperience" component='div'
                                      className='font-sans bg-red-300 p-1'/>
                      </F>
                      <F label='Years of Fashion Styling Experience'>
                        <TField name='yearsOfFashionStylingExperience'/>
                        <ErrorMessage name="yearsOfFashionStylingExperience" component='div'
                                      className='font-sans bg-red-300 p-1'/>
                      </F>
                      <F label='Fashion education?'>
                        <div className="flex">
                          <RadioButton name='education' value='true' label='Yes'/>
                          <RadioButton name='education' value='false' label='No'/>
                        </div>
                      </F>
                      <F label='If yes, what school?'>
                        <TField name='school'/>
                      </F>
                      <F label='Please Briefly Describe Your Experience In Fashion And Styling'>
                        <TField name='experience' rows={9} component='textarea'/>
                      </F>
                      <F label='Men or Women Styling?'>
                        <div className="flex">
                          <RadioButton name='menWomen' value='Men' label='Men'/>
                          <RadioButton name='menWomen' value='Women' label='Women'/>
                          <RadioButton name='menWomen' value='Both' label='Both'/>
                        </div>
                      </F>
                      <F label='Favorite Style'>
                        <TField name='favoriteStyle'/>
                      </F>
                      <F label='What Is Your Typical Hourly Rate?'>
                        <TField step={1} name='hourlyRateDollar'
                                placeholder='Rate in dollar without comma, for example 123'/>
                        <ErrorMessage name="hourlyRateDollar" component='div'
                                      className='font-sans bg-red-300 p-1'/>
                      </F>
                      <F label='What Is Your Typical Day Rate?'>
                        <TField step={1} name='dailyRateDollar'
                                placeholder='Rate in dollar without comma, for example 123'/>
                        <ErrorMessage name="dailyRateDollar" component='div'
                                      className='font-sans bg-red-300 p-1'/>
                      </F>
                      <F label='Cities Of Work'>
                        <TField name='citiesOfWork' rows={3} component='textarea'/>
                      </F>
                      <F label='Countries Of Work'>
                        <TField name='countriesOfWork' rows={3} component='textarea'/>
                      </F>
                      <F label='Typical Days Of Availability'>
                        <CheckboxGroup name='availabilities' values={[
                          'Monday',
                          'Tuesday',
                          'Wednesday',
                          'Thursday',
                          'Friday',
                          'Saturday',
                          'Sunday',
                        ]}/>
                      </F>
                      <F label='Type Of Commitment'>
                        <div className="flex">
                          <RadioButton name='commitment' value='Freelance' label='Freelance'/>
                          <RadioButton name='commitment' value='FullTime' label='Full Time'/>
                          <RadioButton name='commitment' value='Both' label='Both'/>
                        </div>
                      </F>
                      <F label='In What Ways Would You Like To Contribute To The LookStyler Platform?'>
                        <CheckboxGroup name='contributions' values={[
                          'Blogging_and_social_media',
                          'Virtual_Styling',
                          'Personal_Shopping_In_Your_Local_City',
                          'Personal_Shopping_Abroad',
                          'Establishing_Local_Partnerships_With_Stores_And_Designers'
                        ]}/>
                      </F>
                      <F
                        label='Are You Interested In Being Featured On The Platform So You Can Be Booked For Personal Shopping?'>
                        <div className="flex">
                          <RadioButton name='featured' value='Yes' label='Yes'/>
                          <RadioButton name='featured' value='No' label='No'/>
                          <RadioButton name='featured' value='Maybe' label='Maybe'/>
                        </div>
                      </F>
                      <F
                        label='Are You Interested In Becoming An Ambassador Getting Access To Higher Commissions and Exclusive Packaging Opportunities? Ambassadors will be picked for each city based on their performance and additional contributions to the platform. Ambassadors will receive higher commissions and be featured in exclusive packaging.'>
                        <div className="flex">
                          <RadioButton name='ambassador' value='Yes' label='Yes'/>
                          <RadioButton name='ambassador' value='No' label='No'/>
                          <RadioButton name='ambassador' value='Maybe' label='Maybe'/>
                        </div>

                      </F>
                      <button
                        className='text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'
                        type="submit" disabled={isSubmitting}>
                        Submit
                      </button>
                      {isSubmitting ?
                        <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/> : null}
                      <div
                        className={'my-2 w-full font-sans font-light py-2 px-8 ' + (status ? (status.error ? 'bg-red-300' : 'bg-green-300') : 'invisible')}>{status && status.error ? 'Something went wrong, please try again later' : 'Your stylist profile was saved successfully. Please note that it might take maximum 24h before changes are published on the website.'}</div>
                    </Form>;
                  }
                  }
                </Formik>
              }
            </Mutation>

          }}
        </Query>
      </div>
      <Img className='hidden lg:block lg:w-1/2' fluid={p.image.fluid}/>
    </div>
  </MyAccountLayout>
};
export default StylistProfile

export const query = graphql`
    query StylistForm {
        datoCmsStylistformpage {
            image {
                fluid(maxWidth: 800, imgixParams: {fit: "crop", w:"800", h:"1000", crop:"left" }) {
                    ...GatsbyDatoCmsFluid
                }
            }
        }
    }

`